import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
export const StyledParagraphAndCarouselReviewsDesktopComponent = styled.div`
  max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
  margin: auto;

  .side-by-side-text-and-asset-component {
    .side-by-side-content {
      .full-paragraph-component-wrapper {
        padding-bottom: 100px;
      }
    }
  }

  .image-wrapper {
    filter: drop-shadow(-16px 23px 56px rgba(29, 140, 242, 0.3));
  }
`;
